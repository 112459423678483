/* You can add global styles to this file, and also import other style files */

$page-top-bottom-margin: 40px;

@import "custom-theme.scss";

@font-face {
  font-family: "RobotoReg";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "KhBold";
  src: url("./assets/fonts/kh/Battambang-Regular.ttf");
}

@font-face {
  font-family: "KhReg";
  src: url("./assets/fonts/kh/Battambang-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  font-family: "RobotoReg";
}

html,
body {
  height: 100%;
}

.example-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #eee;
}

.clear {
  clear: both;
}

.auto-space {
  flex: 1 1 auto;
}

.container-fluid {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

mat-form-field {
  margin-top: 15px;
}

.success-snackbar {
  background-color: #28a745;
  color: #ffffff;
  text-decoration: none;
}

.error-snackbar {
  background-color: #dc3545;
  color: #ffffff;
  text-decoration: none;
}

.recommend-snackbar {
  background-color: #3e5991;
  color: #ffffff;
  text-decoration: none;
}

.btn-green {
  background-color: #28a745;
  color: #ffffff;
  text-decoration: none;
}

.btn-grey {
  background-color: #e2e6ea;
  color: #000000;
  text-decoration: none;
}

.btn-blue {
  background-color: #3e5991;
  color: #ffffff;
  text-decoration: none;
}

.btn-primary-light {
  background-color: #7698de;
  color: #ffffff;
  text-decoration: none;
}

.btn-red {
  background-color: #91763e;
  color: #ffffff;
  text-decoration: none;
}

.btn-yellow {
  background-color: #ffc107;
  color: #ffffff;
  text-decoration: none;
}

.btn-cyan {
  background-color: #2e93b8;
  color: #ffffff;
  text-decoration: none;
}

.btn-orange {
  background-color: #c47c41;
  color: #ffffff;
  text-decoration: none;
}

// btn outline

.btn-green-outline {
  border: 1px solid #28a745;
  color: #28a745;
  font-weight: bold;
}

.btn-blue-outline {
  border: #3e5991 1px solid !important;
  color: #3e5991;
  font-weight: bold;
}

.btn-red-outline {
  border: #91763e 1px solid !important;
  color: #91763e;
  font-weight: bold;
}

.btn-yellow-outline {
  border: #ffc107 1px solid !important;
  color: #ffc107;
  font-weight: bold;
}

.btn-cyan-outline {
  border: #2e93b8 1px solid !important;
  color: #2e93b8;
  font-weight: bold;
}

.btn-orange-outline {
  border: #c47c41 1px solid !important;
  color: #c47c41;
  font-weight: bold;
}

button {
  text-transform: capitalize !important;
}

// print section

@media print {
  * {
    box-shadow: none !important;
    text-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: visible !important;
  }

  // this block is for tourist info print
  .not-for-print {
    display: none !important;
  }

  .print-area {
    height: 100vh;
    width: 100vw;
    padding: 20px;
    margin: 0;
    page-break-inside: auto !important;
    page-break-after: always !important;
    position: absolute;
    display: block;

    .print-title {
      text-align: center;
      font-size: 14pt;
    }

    ul {
      width: 100%;
      list-style: none;
      padding: 10px !important;
      page-break-inside: always !important;
      page-break-after: always !important;

      li {
        height: 150px;
        width: 100%;
        padding: 10px !important;
        border-bottom: 1px solid #cccccc;
        position: relative;
        page-break-after: always !important;
        page-break-inside: avoid !important;

        .img-box {
          width: 130px;
          height: 130px;
          background-position: center center;
          background-repeat: no-repeat;
          float: left;

          .tourist-pic {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .info-box {
          width: calc(100% - 30px);
          height: 100%;
          float: right;
          padding: 10px !important;
          position: relative;

          p {
            font-size: 1.5rem;
            margin-bottom: 5px !important;
          }

          .nationality {
            font-weight: bold;
          }
        }
      }
    }
  }

  .cdk-overlay-pane,
  .mat-dialog-content {
    height: 100% !important;
    width: 100% !important;
    max-width: none !important;
    max-height: none !important;
    display: block !important;
    overflow-x: hidden !important;
    overflow-y: visible !important;
    page-break-inside: auto !important;
    page-break-after: always !important;
    page-break-before: always !important;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  .mat-drawer-container {
    background: transparent !important;
    background-color: white !important;
    position: relative !important;
  }

  .paper-header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: $page-top-bottom-margin;
    border-bottom: 2px solid #3d4a9e;

    .logo {
      position: absolute;
      left: 0;
      bottom: 0;
      height: $page-top-bottom-margin;
      width: 100%;

      h4 {
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        font-size: 12pt;
        transform: translate(-50%, -50%);
      }
    }
  }

  .page-footer {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100%;
    height: $page-top-bottom-margin;

    .web {
      display: grid;
      align-items: center;
      text-align: right;
      grid-template-columns: minmax(5px, 1fr) auto 50px;
      grid-gap: 5px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 10pt;
      width: 100%;
      position: absolute;
      bottom: $page-top-bottom-margin;

      &::after,
      &::before {
        content: "";
        border-top: 2px solid #3d4a9e;
      }
    }

    .print-details {
      text-align: left !important;
      font-size: 8pt !important;
    }
  }

  .paper-content {
    display: block;
    padding-bottom: 80px !important;
    height: 100% !important;
    position: relative;

    p {
      font-size: 8pt;
    }

    h4 {
      font-size: 12pt;
    }

    .space {
      height: 50px !important;
    }

    #report-table {
      font-size: 10pt;
      text-align: center;
      min-height: 100% !important;
      padding-bottom: 40px !important;
      page-break-inside: auto !important;

      tr,
      th,
      td {
        page-break-inside: avoid !important;
        page-break-after: auto;
      }

      tfoot {
        tr {
          td {
            border: none !important;
          }
        }
      }
    }

    .total-details {
      margin-top: 10px !important;
      text-align: right;
      margin-right: 10px !important;

      p {
        font-size: 12pt;
      }

      span {
        font-size: 12pt;
        font-weight: bold;
      }
    }
  }

  .container-fluid {
    padding: 0 !important;
  }

  .not-print-element {
    display: none;
  }

  .column {
    display: block;
    float: left;
    width: 33.33%;
    min-height: 50px;
    position: relative;

    h4 {
      text-align: center;
      margin-bottom: 5px !important;
    }

    p {
      text-align: center;
    }
  }

  mat-toolbar,
  mat-sidenav,
  .not-for-print {
    display: none !important;
  }
}

@media screen {
  .print-element,
  .paper-header,
  .page-footer,
  .total-details,
  .print-title {
    display: none !important;
  }
}

// remove tinymce warning

.mce-notification {
  display: none !important;
}

.rich-text-content {
  p {
    img {
      height: 50px !important;
      width: auto !important;
    }
  }
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

.tour-group-item {
  &.active {
    .mat-progress-bar .mat-progress-bar-fill::after {
      background-color: white !important;
    }
  }
}
